.Hero {
  padding-left: 6rem;
  padding-right: 6rem;
  padding-top: 6rem;
  padding-bottom: 0;
  display: flex;
  position: relative;
  text-align: center;
  color: #172036;
}

.DisclaimerWrapper {
  padding: 6rem;
  padding-top: 3rem;
  padding-bottom: 1rem;
  display: flex;
  position: relative;
  text-align: center;
}

.HeroMainTextWrapper {
  width: 75%;
  margin-top: 200px;
  text-align: left;
  // backdrop-filter: blur(87px);
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 39px;
  // margin-left: 20px;
}

.HeroMainCTAAndVideo {
  width: 25%;
  padding-top: 30px;
  margin-top: 200px;
  text-align: right;
  display: flex;
  flex-direction: column;
  align-content: flex-end;
  align-items: flex-end;
  justify-content: space-evenly;
}

.HeroMainCTA {
  padding-right: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.HeroMainText {
  font-size: 4rem;
  font-weight: bold;
  font-family: "DM Sans", sans-serif;
}

.HeroMainSubText {
  font-size: 1.5rem;
  font-family: "DM Sans", sans-serif;
}

.Announcement {
  position: absolute;
  width: 100%;
  z-index: 6;
  margin-top: 150px;
  text-align: center;
  display: flex;
  justify-content: center;
}

.AnnouncementText {
  box-sizing: border-box;
  width: auto;
  padding: 10px;
  color: black;
  background: #ffffff;
  border: 2px solid #e0e0f1;
  border-radius: 13px;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;

  color: #172036;

  a {
    color: #0047ff;
  }
}

@media (min-width: 1500px) {
  .HeroMainText {
    font-size: 5rem;
  }
}

@media (max-width: 1280px) {
  .Hero {
    // padding: 90px;
    padding: 0;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: 3rem;
  }
}
@media (max-width: 1280px) and (min-width: 1024px) {
  .Hero {
    > div:first-child {
      width: 75%;
    }

    > div:last-child {
      width: 25%;
    }
  }
}

@media (max-width: 1024px) {
  .Hero {
    display: flex;
    flex-direction: column;
  }

  .HeroMainCTAAndVideo {
    width: 100%;
  }

  .Announcement {
    padding: 30px;
  }

  .HeroMainTextWrapper {
    width: 100%;
    padding-right: 40px;
  }

  .HeroMainText {
    font-size: 3rem;
  }

  .HeroMainCTAAndVideo {
    margin-top: 0;
    align-items: center;
  }

  .DisclaimerWrapper {
    padding: 2rem;
  }
}

.HeroWithAnnouncement {
  padding-top: 100px;
}
