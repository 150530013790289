.Wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .NoCardCreditRequired {
    margin-top: 15px;
    color: black;
    text-align: center;
    text-shadow: 0px 1px 13px white;
  }
}

@media (min-width: 1024px) {
  .Wrapper {
    width: 600px;

    div {
      width: 600px;

      button {
        margin-top: 15px;
        margin-left: 15px;
        margin-right: 15px;

        width: 400px;
      }
    }
  }
}

@media (max-width: 1024px) {
  .Wrapper {
    div {
      width: 350px;

      button {
        width: 300px;
      }
    }
  }
}
