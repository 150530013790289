.Wrapper {
  padding-left: 6rem;
  padding-right: 6rem;
  padding-top: 6rem;
  padding-bottom: 0;

  // clip-path: polygon(0 2%, 100% 0%, 100% 98%, 0% 100%);
  // background-color: #172036;
  // padding-top: 10%;
  // padding-bottom: 10%;
  // padding-left: 100px;
  // padding-right: 100px;
  // width: 101%;
}

.HeaderTitle {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 90px;
  color: black;
  text-align: center;
  margin-bottom: 0.5rem;
}

.HeaderSubTitle {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 32px;
  margin-bottom: 3rem;
  leading-trim: both;
  text-edge: cap;
  text-align: center;
  color: black;
}
.HeaderLevel3 {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  margin-bottom: 3rem;
  leading-trim: both;
  text-edge: cap;
  text-align: center;
  color: black;
}

.VideoCards,
.PersonCards {
  display: flex;
  // padding-left: 125px;
  // padding-right: 125px;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-bottom: 10px;
  align-items: center;
  justify-content: space-between;
  overflow-x: scroll;
  overflow-y: hidden;
}

.VideoCardPlayButton:hover {
  transform: scale(1.02);
}

.VideoCardPlayButton {
  display: flex;
  transition: transform 0.3s ease;
  align-items: center;
  justify-content: center;
}

.VideoCard:hover {
  transform: scale(1.02);
}

.VideoCard {
  cursor: pointer;
  transition: transform 0.3s ease;
  width: 310px;
  height: 450px;
  background-color: gray;
  border-radius: 20px;
  // margin: 1rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.VideoCardTitle,
.VideoCardSubTitle {
  width: 310px;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  leading-trim: both;
  text-edge: cap;
  text-align: center;
  color: #ffffff;
  text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.45);
}

.PersonCard {
  width: 424px;
  height: 243px;
  left: 295px;
  top: 1897px;
  // margin: 1rem;
  padding: 1rem;

  &:not(:first-of-type) {
    margin-left: 1rem;
  }

  background: #1d263f;
  border-radius: 20px;
}

.PersonCardPersonName,
.PersonCardTestimonial {
  color: white;
}

.PersonCardPersonOccupation {
  color: #93a1b1;
}

.PersonCardAvatar {
  box-sizing: border-box;
  overflow: hidden;

  width: 75px;
  height: 75px;

  border: 2px solid #acffcd;
  border-radius: 15px;
}

.PersonCardHeaderWrapper {
  display: flex;
  margin-bottom: 10px;
}

.PersonCardPerson {
  margin-left: 10px;
}

@media (max-width: 1280px) and (min-width: 1024px) {
  .VideoCards,
  .PersonCards {
    display: flex;
    align-items: center;
    // justify-content: left;
    overflow-x: scroll;
    overflow-y: hidden;
    padding-right: 50px;
  }
}

@media (max-width: 1024px) {
  .Wrapper {
    padding: 10px;
    padding-top: 150px;
    padding-bottom: 150px;
  }

  .HeaderTitle {
    line-height: 45px;
    font-size: 32px;
  }

  .HeaderSubTitle {
    line-height: 26px;
    font-size: 18px;
  }

  .VideoCards,
  .PersonCards {
    display: flex;
    align-items: center;
    justify-content: left;
    overflow-x: scroll;
    overflow-y: hidden;
    padding-right: 50px;
  }

  .VideoCardTitle,
  .VideoCardSubTitle {
    width: 250px;
  }

  .PersonCardPersonName,
  .PersonCardTestimonial {
    min-width: 200px;
  }

  .VideoCard {
    &:not(:first-of-type) {
      margin-left: 1rem;
    }
  }

  .VideoCard:hover {
    transform: scale(1.02);
  }
}
