.Wrapper {
  padding-left: 6rem;
  padding-right: 6rem;
  padding-top: 12rem;
  padding-bottom: 0;
  color: black;
}

.HeaderTitle {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 90px;
  color: black;
  text-align: center;
  margin-bottom: 0.5rem;
}

.HeaderSubTitle {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 32px;
  margin-bottom: 3rem;
  leading-trim: both;
  text-edge: cap;
  text-align: center;
  color: black;
}

.HowWorksCardInfo {
  display: flex;
  margin-top: 20px;
}

.HowWorksCardStep {
  padding: 20px;

  margin-right: 20px;
  color: white;
  font-weight: bold;
  border-radius: 15px;
  font-size: 25px;
}

.HowWorksCardTitle {
  font-family: "DM Sans";
  font-weight: bold;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;

  leading-trim: both;
  text-edge: cap;
  text-align: left;
  color: black;
}

.HowWorksCards {
  display: flex;
  // padding-left: 125px;
  // padding-right: 125px;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-bottom: 10px;
  align-items: flex-start;
  justify-content: space-between;
  overflow-x: scroll;
  overflow-y: hidden;
}

.HowWorksCard {
  width: 424px;
  height: auto;
  border-radius: 20px;

  margin-left: 10px;

  &:hover {
    transform: scale(1.02);
    transition: transform 0.3s ease;
  }
}

.HowWorksCardImage {
  box-sizing: border-box;
  overflow: hidden;

  border-radius: 15px;
}

.HowWorksCardHeaderWrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

@media (max-width: 1280px) and (min-width: 1024px) {
  .HowWorksCards {
    display: flex;
    overflow-x: scroll;
    overflow-y: hidden;
    padding-right: 50px;
  }
}

@media (max-width: 1024px) {
  .Wrapper {
    padding: 10px;
  }

  .HeaderTitle {
    line-height: 45px;
    font-size: 32px;
  }

  .HeaderSubTitle {
    line-height: 26px;
    font-size: 18px;
  }

  .HowWorksCardImage {
    width: 271px;
  }

  .HowWorksCards {
    display: flex;
    align-items: center;
    justify-content: left;
    overflow-x: scroll;
    overflow-y: hidden;
    padding-right: 50px;
  }
}
