@media (min-width: 1500px) {
  .Wrapper {
    padding: 160px;
  }
}

.Logo {
  padding-right: 20px;
}

.CompanyAddress,
.CompanyLegal {
  margin-top: 50px;

  p:first-of-type {
    margin-top: 20px;
  }
}

.Wrapper {
  color: #fff;
  box-sizing: border-box;
  width: 100%;
  height: 500px;
  margin: 0 auto;
  width: 100%;
  margin-top: 100px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.CardMindR {
  background-image: url(/assets/footer-back.webp);
}

.Card {
  position: absolute;
  left: 0px;
  width: 100%;
  padding-bottom: 50px;
  padding-left: 6rem;
  // height: 1000px;
  padding-right: 6rem;
  padding-top: 55px;
  z-index: 10;
  /* margin: 80px; */

  background: #172036;

  background-repeat: no-repeat;
  background-position: 0 -32px;
  background-size: cover;
}

.Row {
  display: flex;
  justify-content: space-between;

  > div {
    margin-right: 20px;
  }
}

.Legal {
  margin-top: 50px;
  padding-top: 20px;
  border-top: 2px solid #545f74;
  width: 100%;
  color: #d5dbe9;
  text-align: center;
  span {
    padding: 0 10px;
  }
}

.Social,
.Menu {
  h2 {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 34px;
  }
}

.Menu {
  display: grid;
  a {
    cursor: pointer;
    padding-top: 20px;
    font-family: "DM Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 34px;
  }
}

@media (max-width: 1024px) {
  .Wrapper {
    padding: 10px;
    padding-top: 50px;
    padding-bottom: 0px;
    height: 1350px;
    background-size: contain;
  }

  .Card {
    padding-bottom: 50px;
    padding-left: 20px;
    padding-right: 20px;
    height: auto;
  }

  .Row {
    display: flex;
    flex-direction: column;
    .Logo,
    .Menu,
    .Social {
      padding-top: 30px;
      padding-bottom: 30px;
    }
  }

  .Legal {
    display: flex;
    flex-direction: column;
    span {
      margin-top: 10px;
    }
  }
}
