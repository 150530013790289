.MainWrapper {
  // padding-left: 6rem;
  // padding-right: 6rem;

  background-color: white;
  width: 100vw;
  max-width: 1800px;
  color: white;
  margin-top: -167px;
  background-size: 111% 34%;
  background-image: url(/assets/hero-back.webp);

  background-repeat: no-repeat;
}
