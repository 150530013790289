.Wrapper {
  padding-left: 6rem;
  padding-right: 6rem;
  padding-top: 6rem;
  padding-bottom: 0;

  z-index: 1;
  position: relative;
}

.HeaderTitle {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 90px;
  color: #172036;
  text-align: center;
  margin-bottom: 0.5rem;
}

.HeaderSubTitle {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 32px;
  margin-bottom: 3rem;
  leading-trim: both;
  text-edge: cap;
  text-align: center;
  color: #172036;
}

.BlogCards {
  display: flex;
  justify-content: space-between;
}

.BlogCard:hover {
  transform: scale(1.02);
}

.BlogCard {
  transition: transform 0.3s ease;
  box-sizing: border-box;
  padding: 20px;
  width: 400px;
  min-width: 276px;
  height: 725px;
  overflow: hidden;

  cursor: pointer;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  border: 2px solid #172036;
  border-radius: 20px;

  &:not(:first-of-type) {
    margin-left: 1rem;
  }
}

.BlogCardTitle {
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 10px;
}
.BlogCardDescription {
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  overflow: hidden;
}

.BlogCardTitle,
.BlogCardAuthor,
.BlogCardDescription {
  color: #172036;
  leading-trim: both;
  font-family: "DM Sans";
  font-style: normal;
  text-edge: cap;
  color: #172036;
}

.BlogCardAuthor span {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #172036;
}

.BlogCardAuthor {
  font-size: 14px;
  line-height: 24px;
  color: #545f74;
  padding-left: 10px;
}

.BlogCardImage {
  box-sizing: border-box;
  overflow: hidden;
  width: 305px;
  height: 194px;
  border-radius: 20px;
  overflow: hidden;
}

@media (min-width: 1500px) {
  .BlogCardImage {
    width: 355px;
    height: 244px;
  }
}

.BlogCardHeaderWrapper {
  display: flex;
  margin-bottom: 10px;
}

.BlogCardBlog {
  margin-left: 10px;
  padding-bottom: 10px;
  overflow: hidden;
}

@media (max-width: 1280px) and (min-width: 1024px) {
  .Wrapper {
    padding: 70px;
    padding-top: 0;
    padding-bottom: 0;
  }
}

@media (max-width: 1024px) {
  .Wrapper {
    padding: 10px;
    padding-top: 0;
    padding-bottom: 0;
  }

  .HeaderTitle {
    line-height: 45px;
    font-size: 32px;
  }

  .HeaderSubTitle {
    line-height: 26px;
    font-size: 18px;
  }

  .BlogCards {
    display: flex;
    align-items: center;
    justify-content: left;
    overflow-x: scroll;
    overflow-y: hidden;
    padding-right: 50px;
  }

  .BlogCard {
    width: 350px;
    height: 525px;
  }

  .BlogCardImage {
    width: 250px;
    margin-bottom: 10px;
  }

  .BlogCardAuthor {
    padding: 10px;
  }
}
