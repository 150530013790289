.VideoPlayerWrapper:hover {
  transform: scale(1.02);
}

.VideoPlayerWrapper {
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: flex-end;
  padding-bottom: 0px;
  transition: transform 0.3s ease;
  button {
    transition: transform 0.3s ease;
  }
  button:hover {
    transform: scale(1.02);
  }
}

.VideoPlayer {
  display: flex;

  align-items: center;
  width: 600px;
  justify-content: flex-end;
}

@media (max-width: 1024px) {
  .VideoPlayerWrapper {
    margin-top: 1rem;
    padding-top: 0;
    max-height: 200px;
  }

  .VideoPlayer {
    width: 100%;
  }
}
