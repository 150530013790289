.Wrapper {
  padding-left: 6rem;
  padding-right: 6rem;
  padding-top: 6rem;
  padding-bottom: 0;

  box-sizing: border-box;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100vw;
  margin: 0 auto;
  width: 100%;
  position: relative;
  z-index: 1;
}

.Wrapper::after {
  content: "";
  position: absolute;
  clear: both;
  z-index: -1;
  width: 100vw;
  height: 1000px;
  top: -20px;
  background-size: 1000px;
  left: 0px;
  background-image: url(/assets/home-cta-back.webp);
  background-repeat: no-repeat;
  background-position: 0 -32px;
}

.Card {
  padding-left: 98px;
  padding-right: 40%;
  padding-top: 55px;
  padding-bottom: 55px;
  // margin: 80px;
  border: 2px solid #000000;
  border-radius: 20px;
  backdrop-filter: blur(190px);
  background-image: url("/assets/home-cta-illustration.svg");
  background-repeat: no-repeat;
  background-position: 101% 101%;
}

.Title {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 54px;
  color: #172036;
  text-align: left;
  margin-bottom: 3rem;
  word-break: break-word;
}

@media (max-width: 1280px) and (min-width: 1024px) {
  .Card {
    padding-left: 20px;
  }
}

@media (max-width: 1024px) {
  .Wrapper {
    padding: 20px;
    padding-top: 0;
    padding-bottom: 75px;
  }

  .Card {
    margin: 0;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 90%;
    background-size: contain;
  }

  .Title {
    font-size: 40px;
    line-height: 48px;
  }
}
