.Wrapper {
  padding-left: 6rem;
  padding-right: 6rem;
  padding-top: 6rem;
  padding-bottom: 0;
  color: black;
  width: 100%;
  box-sizing: border-box;
}

.HeaderTitle {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 90px;
  color: black;
  text-align: center;
  margin-bottom: 0.5rem;
}

.HeaderSubTitle {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 32px;
  margin-bottom: 3rem;
  leading-trim: both;
  text-edge: cap;
  text-align: center;
  color: black;
}

.HomeMainButtons {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.FAQsAccordions {
  column-count: 2;
  column-gap: 1em;

  h3 {
    font-weight: bold;
    font-size: 1.5rem;
  }
}

.FAQsAccordion {
  break-inside: avoid;

  margin-bottom: 1em;

  min-width: 50%;
}

@media (max-width: 1024px) {
  .FAQsAccordions {
    column-count: 1;
  }
  .Wrapper {
    padding: 10px;
  }

  .HeaderTitle {
    line-height: 45px;
    font-size: 32px;
  }

  .HeaderSubTitle {
    line-height: 26px;
    font-size: 18px;
  }
}
